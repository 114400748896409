import Cookies from 'js-cookie';
import { inFifteenHours } from '@/helper/filter/date';
import { isEmptyValue } from '@/helper/data-process';

export const getFatherDomain = () => {
  if (/^localhost(:\d+)?$/.test(document.domain)) return 'localhost';
  // 統一在非 localhost 環境時皆固定為 .eslite.com
  return `.eslite.com`;
};

/**
 * default cookie options
 * @type {{path: string, expires: number, domain: any, sameSite: string, Secure: boolean}}
 */
const cookieOption = { expires: inFifteenHours(1), path: '/', domain: getFatherDomain(), sameSite: 'strict', Secure: true };

/**
 * 設定 cookie
 * @param {string} name cookie 名稱
 * @param {any} value cookie 值
 * @param {number} hour cookie 存留時間(分鐘)
 * @return {string} cookie
 */
export const setCookie = (name, value, hour = 1) => Cookies.set(name, value, { ...cookieOption, expires: inFifteenHours(hour) });

/**
 * get cookie
 * @param {string} cookieName cookie name
 * @return {*}
 */
export const getCookie = (cookieName) => Cookies.get(cookieName, { path: cookieOption.path });

/**
 * 取得所有 auth. 開頭跟 isLogin 的 cookie name
 * @returns {array} all auth. and isLogin cookie name
 */
export const getAuthCookiesName = () => {
  const allCookies = Cookies.get(); // 取得所有 cookies
  return Object.keys(allCookies)
    .filter((key) => key.startsWith('auth.') || key === 'isLogin') // 篩選 auth. 開頭 或是 isLogin
    .reduce((result, key) => {
      result[key] = allCookies[key];
      return result;
    }, {});
};

/**
 * remove cookie
 * @param {string} cookieName cookie name
 * @return {*}
 */
export const removeCookie = (cookieName) => Cookies.remove(cookieName, { path: cookieOption.path });

/**
 * 移除所有 cookie
 * 會包含所有登入狀態及 tracking code
 */
export const removeAllCookie = () => Object.keys(Cookies.get()).forEach((cookieName) => Cookies.remove(cookieName));
export const removeAllAuthsCookie = () => {
  Object.entries(getAuthCookiesName()).map((cookieName) => Cookies.remove(cookieName));
  Object.entries(getAuthCookiesName()).map((cookieName) => Cookies.remove(cookieName)); // 重複第二次以確保不同 domain 的
};

/**
 * 移除相同前輟的 cookie 只保留目前版號的 cookie
 * @param {string} prefix 前輟 (必填)
 * @param {string} currentVersion 版號 (必填)
 * @return {boolean} 回傳結果
 */
export const clearOldVersionCookies = (prefix = '', currentVersion = '') => {
  // 條件若有空值則不做處理
  if (isEmptyValue(prefix) || isEmptyValue(currentVersion)) return false;

  // 取得所有的 cookie
  const cookies = Cookies.get();

  Object.keys(cookies).forEach((name) => {
    // 檢查 cookie 名稱是否符合前綴且不是目前版號
    if (name.startsWith(prefix) && !name.endsWith(currentVersion)) {
      // 刪除非目前版本的 cookie
      Cookies.remove(name, { path: cookieOption.path });
    }
  });

  return true;
};
