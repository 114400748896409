import axios from 'axios';

const API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_MEMBER_ADDRESS_BOOK}`;

export const getAddressBook = (token, params) =>
  axios({
    method: 'get',
    headers: { Authorization: token },
    url: API_PATH,
    params,
  });

export const deleteAddressBook = (token, id = '') =>
  axios({
    method: 'delete',
    headers: { Authorization: token },
    url: `${API_PATH}/${id}`,
  });
