/* eslint-disable no-self-assign */
/* eslint-disable no-underscore-dangle */
import { defineStore } from 'pinia';
import { removeCartInfo } from '@/helper/cart/cart-default-data';
import { removeCookie, removeAllAuthsCookie } from '@/helper/cookie';
import { defKey } from '@/constant/auth';
import { useCartItemsStore } from '@/stores/cart-items';

const actions = {
  async logout() {
    await removeCartInfo(this.$app.$auths.user());
    await this.$app.$auths
      .logout()
      .then(() => {
        const cartItemsStore = useCartItemsStore();
        // clear local cart
        cartItemsStore.clearLocalCartAndStore();

        // 完全清除登入資訊變數
        removeAllAuthsCookie();
        localStorage.removeItem(defKey.refreshToken);
        localStorage.removeItem(defKey.token);
        localStorage.removeItem(defKey.strategy);

        // refresh: 原地 refresh 該去登入頁的由 middleware 控制
        window.location.href = window.location.href;
      })
      .catch((error) => {
        const errorMessage = typeof error === 'object' ? JSON.stringify(error) : error;
        throw new Error(`login fail, error is => ${errorMessage}`);
      });
  },
};

export const useLogoutStore = defineStore('logout', {
  actions,
});
