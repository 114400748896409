import { ogLogoUrl, titleAndAlternate, domain, fbAppID } from '@/constant/seo';

/**
 * 預設 meta
 * @type {{title: string}}
 */
const defaultMeta = {
  title: titleAndAlternate,
};

/**
 * 預設 meta
 * @return {{title: string}}
 */
export const getDefaultMeta = () => ({
  ...defaultMeta,
});

export const categoryL1Metas = {
  // TODO: 電子書 description 待補充
  ebook: {
    name: `電子書`,
    keywords: `電子閱讀器, 電子書, 誠品網路書店, 誠品線上`,
    description: `海量電子書隨身帶著走，讓閱讀變得無比輕鬆！無論是扣人心弦的小說、深入淺出的學術專書、實用的商業知識，還是自我成長書籍，應有盡有。隨時隨地開啟智慧與想像的無限可能，讓知識觸手可及，讓學習與娛樂融為一體！`,
  },
  chinese: {
    name: `中文書`,
    keywords: `新書推薦, 中文書, 新書列表, 誠品網路書店, 誠品線上`,
    description: `文青是一種思想、一種生活態度。享受一個人獨處，用一本書陪伴整個下午。誠品提供最新出版書訊及新書推薦，齊全的華文出版品：含文學、藝術、生活、圖文漫畫、科普、人文社科、商業財經、語言考用、電腦書…等。`,
  },
  foreign: {
    name: `外文書`,
    keywords: `外文書, 誠品網路書店, 誠品線上`,
    description: `誠品線上外文書館與英美日最新閱讀趨勢同步，以空運現貨提供各類原文書。推薦熱門書籍包括文學、青少年文學、建築、藝術、設計、生活、人文、社科、商業行銷、語言學習、雜誌特刊等。`,
  },
  // TODO: 日文書 description 待補充
  japanese: {
    name: `日文書`,
    keywords: `日文書, 誠品網路書店, 誠品線上`,
    description: `精選日文書籍，讓你沉浸於原汁原味的日語世界！從文學經典、商業趨勢到語言學習與生活指南，幫助你提升語感、擴展視野，無論是日語學習者還是日文愛好者，都能享受最純正的閱讀體驗，感受日語文化的獨特魅力！`,
  },
  magazine: {
    name: `雜誌`,
    keywords: `雜誌, MOOK, 誠品網路書店, 誠品線上`,
    description: `誠品線上雜誌館提供流行、知識、生活、美感的最新情報，以及種類豐富的超值附錄贈品雜誌。包含中文雜誌、日文雜誌、MOOK、英文雜誌及其他語言雜誌。`,
  },
  // TODO: 童書 description 待補充
  child_books: {
    name: `童書`,
    keywords: `兒童,兒童書, 玩教具, 誠品網路書店, 誠品線上`,
    description: `讓孩子愛上閱讀，從小培養想像力與好奇心！我們精選最適合兒童的書籍，涵蓋趣味故事、寓教於樂的繪本、啟發創意的知識書，讓每一本童書都成為孩子探索世界的窗口。輕鬆培養閱讀習慣，讓學習變得有趣又自然！`,
  },
  // TODO: 親子用品 description 待補充
  child_products: {
    name: `親子用品`,
    keywords: `兒童,兒童書, 玩教具, 誠品網路書店, 誠品線上`,
    description: `陪伴寶貝快樂成長，為你挑選最安心的親子用品！從新生兒必備好物到學齡兒童的學習與玩樂用品，每一件都經過精心挑選，確保安全、舒適又實用。讓育兒變得更輕鬆，讓愛與呵護無時無刻陪伴孩子成長！`,
  },
  // TODO: 文具潮藝 description 待補充
  stationery: {
    name: `文具潮藝`,
    keywords: `格文具, 設計文具, 誠品網路書店, 誠品線上`,
    description: `實用與美感兼具的時尚文具與潮流藝術品，讓書寫、學習與創作變得更加有趣！精選高品質筆記本、手帳、設計感十足的文具與辦公用品，讓你的靈感不斷湧現，展現獨特風格，打造屬於自己的美學品味！`,
  },
  // TODO: 服飾鞋包 description 待補充
  apparel: {
    name: `服飾鞋包`,
    keywords: `服飾鞋包, 誠品網路書店, 誠品線上`,
    description: `時尚穿搭從這裡開始！精選潮流服飾、舒適鞋履與時尚包款，讓你輕鬆打造百變風格。無論是日常休閒、正式場合，還是戶外活動，一站式選購，展現你的個人魅力，讓每一天都能自信出眾！`,
  },
  // TODO: 美妝保養 description 待補充
  makeup: {
    name: `美妝保養`,
    keywords: `美妝保養, 誠品網路書店, 誠品線上`,
    description: `由內而外散發迷人光采！嚴選高品質美妝與保養品，涵蓋肌膚護理、彩妝、身體保養等，幫助你呵護每一寸肌膚，打造水潤透亮的動人美肌。不論是日常保養還是精緻妝容，都能輕鬆駕馭，展現最美的自己！`,
  },
  // TODO: 休閒戶外 description 待補充
  leisure: {
    name: `休閒戶外`,
    keywords: `休閒戶外, 誠品網路書店, 誠品線上`,
    description: `擁抱自然，享受戶外樂趣！我們為你精選各類戶外與休閒裝備，無論是登山健行、露營旅行，還是城市運動與戶外探險，都能找到最適合的裝備與配件，讓每一次的冒險都安全又舒適，輕鬆享受大自然的美好！`,
  },
  // TODO: 生活家居 description 待補充
  life: {
    name: `生活家居`,
    keywords: `生活家居, 誠品網路書店, 誠品線上`,
    description: `打造舒適溫馨的居家空間，讓生活更加美好！我們精選高質感家居用品，從收納整理、廚房用品到家居裝飾，讓你的家變得更整潔、美觀又實用。簡單提升生活品質，讓每一天都充滿幸福感與便利！`,
  },
  // TODO: 食品保健 description 待補充
  food: {
    name: `食品保健`,
    keywords: `食品保健, 誠品網路書店, 誠品線上`,
    description: `健康美味，一次滿足！嚴選高品質食品與營養保健品，從日常飲食、養生食品到維他命與保健補充品，讓你輕鬆維持健康與活力。讓吃得健康、補充營養變得更簡單，享受每一天的美好與健康生活！`,
  },
  // TODO: 3C description 待補充
  '3c': {
    name: `3C`,
    keywords: `3C, 誠品網路書店, 誠品線上`,
    description: `掌握最新科技潮流，讓生活更智能！精選智慧手機、筆電、平板、耳機等3C產品與周邊配件，無論是工作、娛樂還是學習，都能享受高效與便利。體驗科技帶來的無限可能，讓數位生活更加輕鬆精彩！`,
  },
  // TODO: 家電 description 待補充
  electronics: {
    name: `家電`,
    keywords: `家電, 誠品網路書店, 誠品線上`,
    description: `高效家電，讓生活更便利舒適！從智慧家電、廚房家電到清潔家電，幫助你輕鬆打理家務，提升生活品質。讓科技融入日常，節省時間與精力，享受更輕鬆的現代智慧生活！`,
  },
  video: {
    name: `影音`,
    keywords: `影音, 誠品網路書店, 誠品線上`,
    description: `誠品線上影音館提供多元的音樂，在數位時代下，購買專輯聆聽與收藏別具意義，無論是流行、電子、搖滾、爵士、古典、新世紀、世界、原聲配樂、有聲CD、SACD等專輯，在主流與非主流間，更能深度探尋誠品音樂所帶來的美好。`,
  },
};

export const metaTitleSeperator = ' │ ';

export const composePageTitle = (titlePrefix) => {
  if (!titlePrefix) return defaultMeta.title;
  if (Array.isArray(titlePrefix)) return [...titlePrefix, defaultMeta.title].join(metaTitleSeperator);
  return `${titlePrefix}${metaTitleSeperator}${defaultMeta.title}`;
};

export const composeMetaInfoForTitleOnly = (title) => ({
  title,
  meta: [{ property: 'og:title', content: title, vmid: 'og:title' }],
});

export const composeMetaInfo = ({ metaTitle, titlePrefix, keywords, description }) => {
  const title = metaTitle || composePageTitle(titlePrefix);
  const meta = [
    // window.location.href 只能在 page metaInfo 是 function 時使用
    { property: 'og:url', content: window.location.href, vmid: 'og:url' },
    // { property: 'og:type', content: 'Article', vmid: 'og:type' },
    // { property: 'og:site_name', content: '誠品線上 - 閱讀與生活的無盡想像', vmid: 'og:site_name' },
    { property: 'og:title', content: title, vmid: 'og:title' },
    { property: 'og:image', content: 'https://www.eslite.com/eslite-og-logo.png', vmid: 'og:image' },
  ];
  if (keywords) meta.push({ property: 'og:keywords', content: keywords, vmid: 'og:keywords' });
  if (description) meta.push({ property: 'og:description', content: description, vmid: 'og:description' });

  return { title, meta };
};
