import { forEachObjIndexed, last, uniq } from 'ramda';
import { isFunction } from '@/helper/data-process';
import { MiddlewareRedirectException } from '@/exception/middleware-exception';
import { useVisitStore } from '@/stores/visit';

/**
 * global middleware
 */
const globalMiddlewares = ['uri', 'system-maintenance', 'login', 'auth-user', 'tracking-code', 'cart-step2', 'category-level', 'recaptcha', 'redirect', 'link-crossorigin']; /** 暫時移除 , 'tagtoo' */

const getMiddlewareImportGlob = () => {
  // middleware 檔案位置
  const modules = import.meta.glob('../../middleware/*.js', { eager: true });
  const modulesByName = {};
  forEachObjIndexed((value, key) => {
    const name = last(key.split('/')).replace('.js', '');
    modulesByName[name] = value;
  }, modules);
  return modulesByName;
};

const middlewareImportGlob = getMiddlewareImportGlob();

const getLocalMiddlewares = (middleware) => {
  if (!middleware) return [];
  if (Array.isArray(middleware)) return middleware;
  return [middleware];
};

const requireMiddlewareFunction = (middleware) => {
  if (isFunction(middleware)) return middleware;
  return middlewareImportGlob[middleware]?.default;
};

export const createRouteMiddleware = function ({ pluginContext, isFirstLoad, pinia }) {
  return async (to, from) => {
    // vue檔案 SFC middleware
    // vue 3
    const localMiddlewares = getLocalMiddlewares(to.meta.middleware);

    // global middleware + vue檔案 SFC middleware
    const middlewares = uniq([...globalMiddlewares, ...localMiddlewares]);
    let redirectArgs = null;
    await middlewares
      .reduce(
        (promise, middleware) =>
          promise.then(() => {
            const middlewareFunction = requireMiddlewareFunction(middleware);
            // 執行 middleware
            return middlewareFunction({
              pinia,
              to,
              from: isFirstLoad.value ? to : from,
              route: to,
              pluginContext,
              redirect: (arg) => {
                if (isFirstLoad.value) {
                  isFirstLoad.value = false;
                  const previous = to.path;
                  const previousParams = to.query || {};
                  const visitStore = useVisitStore();
                  const { setPreviousUri, setPreviousParams } = visitStore;
                  sessionStorage.setItem('visit/PreviousUri', previous);
                  sessionStorage.setItem('visit/PreviousParams', previousParams);
                  setPreviousUri(previous);
                  setPreviousParams(previousParams);
                }
                redirectArgs = arg;
                throw new MiddlewareRedirectException();
              },
            });
          }),
        Promise.resolve(),
      )
      .catch((error) => {
        if (error instanceof MiddlewareRedirectException) {
          return;
        }
        console.log(error);
      });
    // console.log(' redirectArgs', redirectArgs);
    return redirectArgs;
  };
};
