/* eslint-disable no-underscore-dangle */
/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { SwiperSlide } from 'swiper/vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';

import App from '@/App.vue';
import { initRouter } from '@/router';
import { addPlugins } from '@/plugins/main';
import VSwiper from '@/components/global-components/v-swiper.vue';
import { allStoresInit } from '@/stores/index';
import { isBot } from '@/helper/ua';

const isNotPrd = import.meta.env.VITE_TYPE !== 'prd';

/**
 * plugin 必須在 new Vue 之前執行，否則 plugins/route/index 的 router.beforeEach 在 first load 不會被呼叫。
 * 因為 new Vew 之前，沒有 app，因此建立 pluginContext，提供 plugins 及 router middlewawre 引用
 * (原 nuxt 的 app.$auths、app.$gtm... 改用 pluginContext.$auths、pluginContext.$gtm 取代)
 */

const app = createApp(App);
const pinia = createPinia();
// allow pinia store use globalProperties
pinia.use(({ app, store }) => (store.$app = app.config.globalProperties));
// 先 use(pinia)，install auths plugin 時執行 verifyTokenAndInitUser 才能使用 pinia store。
app.use(pinia);
allStoresInit();
const { router, isHistoryPopRef } = initRouter();

const pluginContext = {};
await addPlugins({ pluginContext, router, app, isHistoryPopRef, pinia });

app.use(router);
app.use(VueDOMPurifyHTML, {
  default: {
    // whitelists
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'frameborder', 'allowtransparency', 'allowfullscreen'],
  },
});

/**
 * components
 */
app.component('Swiper', VSwiper);
app.component('SwiperSlide', SwiperSlide);

app.provide('$auths', pluginContext.$auths);
app.provide('$cryptoUtility', pluginContext.$cryptoUtility);

app.mount('#app');
// for test
if (isNotPrd) window.esliteApp = app;

// dev 環境下 增加 debug mode (body 增加 debug-mode class，對應樣式 assets/layer/debug-mode.css)
const appElem = document.getElementById('app');
if (appElem !== null && import.meta.env.VITE_TYPE === 'development') {
  if (appElem.classList !== undefined) appElem.classList.add('debug-mode');
  else appElem.className += ' debug-mode';
}

function loadExternalScripts() {
  const scripts = [
    { id: 'iecheck-script', src: '/ie_alert.min.js' },
    { id: 'tappay-script', async: true, src: '/tappaysdk-v5.19.2.js' },
  ];

  const links = [
    { rel: 'apple-touch-icon', href: '/apple-touch-icon-180x180.jpg', sizes: '180x180' },
    { rel: 'apple-touch-icon', href: '/apple-touch-icon-192x192.jpg', sizes: '192x192' },
  ];

  scripts.forEach(({ id, src }) => {
    if (!document.getElementById(id)) {
      const script = document.createElement('script');
      script.id = id;
      script.src = src;
      script.defer = true;
      document.body.appendChild(script);
    }
  });

  links.forEach(({ rel, href, sizes }) => {
    const link = document.createElement('link');
    link.rel = rel;
    link.href = href;
    if (sizes) link.sizes = sizes;
    document.head.appendChild(link);
  });
}
if (!isBot()) loadExternalScripts();
